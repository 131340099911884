.Contact {
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 50px 0;
}

.Contact button {
  margin: 20px 0;
  padding: 10px 20px;

  cursor: pointer;

  outline: none;

  border-radius: 10px;

  background-color: #242424;
  border: 1px solid #363636;
}

.ContactModal {
  position: fixed; /* Sit on top of the page content */
  /* display: none; Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 200; /* Specify a stack order in case you're using a different order for other elements */

  display: flex;
  align-items: center;
  justify-content: center;
}

.ContactModal-content {
  background-color: #242424;
  border: 1px solid #363636;
  border-radius: 10px;

  height: 30vh;
  min-width: 300px;
  width: 20vw;
  max-width: 600px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  position: relative;
}

.ContactModal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  font-size: 20px;
}

.ContactModal-content form {
  gap: 5px;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  flex-grow: 1;
}

.ContactModal-content form input,
.ContactModal-content form textarea {
  padding: 10px 5px;

  background-color: transparent;
  outline: none;
  border: none;

  border-bottom: 1px solid white;
}

.ContactModal-content form textarea {
  min-height: 80px;
  /* max-height: 300px; */
  height: 100%;

  border-bottom: 1px solid white;
  resize: none;
}

.ContactModal-content form button {
  width: 50%;
  align-self: center;

  margin-bottom: 0;
}
