.Header {
  background-color: rgba(17, 17, 17);

  height: 72px;
  width: 100%;
  padding: 0 20px;

  position: fixed;
  top: 4px;

  border-bottom: 1px solid #242424;

  z-index: 100;
}

.Header:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #e39600, #ea4c89, #8f48eb, #1da1f2);
}

.Header-container {
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 2000px;
}

.Header .left {
  width: min-content;
  text-align: center;
}

.Header .left span {
  color: #fff;
}

.Header .right {
  min-width: 450px;
  max-width: 750px;
  width: 35vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header .right span {
  cursor: pointer;
}

.Header .right button {
  padding: 5px 10px;
  border: none;
  outline: none;
  background-color: #242424;
  border: 1px solid #363636;
  border-radius: 10px;
  cursor: pointer;
}

.Header .hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

@media (max-width: 900px) {
  .Header .hamburger {
    display: block;
  }

  .Header .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .Header .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .Header .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .Header .right {
    position: fixed;
    left: -100%;
    top: 76px;
    padding: 20px 0;
    gap: 30px;
    flex-direction: column;
    background-color: rgba(17, 17, 17);
    width: 100%;
    max-width: 100%;
    text-align: center;
    transition: 0.3s;
  }

  .Header .right.active {
    left: 0;
  }
}
