.App {
  height: calc(100% - 76px);
}
.App .body {
  padding: 0 20vw;
  margin-top: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body::-webkit-scrollbar {
  display: none;
}

.App .body > div {
  width: 100%;
  max-width: 1000px;

  scroll-margin-top: 100px;
}

.App .body > div > h1 {
  color: white;
}

@media (max-width: 900px) {
  .App .body {
    padding: 0 5vw;
  }
}
