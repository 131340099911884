* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: #b1b1b1;
  font-family: MonoLisa, Monaco, "DejaVu Sans Mono", "Lucida Console", monospace;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

body {
  background-color: #111111;
}

button {
  transition: opacity 0.25s;
}

button:hover {
  opacity: 0.7;
}

button:active {
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}
